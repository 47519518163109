@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "AktivGro-Regular";
  src:
    url("./Assets/Fonts/OTF/AktivGrotesk-Regular.otf") format("opentype"),
    url("./Assets/Fonts/TTF/AktivGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "AktivGro-Medium";
  src:
    url("./Assets/Fonts/OTF/AktivGrotesk-Medium.otf") format("opentype"),
    url("./Assets/Fonts/TTF/AktivGrotesk-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "AktivGro-Bold";
  src:
    url("./Assets/Fonts/OTF/AktivGrotesk-Bold.otf") format("opentype"),
    url("./Assets/Fonts/TTF/AktivGrotesk-Bold.ttf") format("truetype");
}

.custom-bullet {
  background: url("./Assets/Images/Checkmark.svg") no-repeat left center;
  padding-left: 24px;
  background-size: 16px;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 18px;
}

.star-bullet {
  background: url("./Assets/Images/Star.svg") no-repeat left center;
  padding-left: 24px;
  background-size: 16px;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 18px;
}

audio::-webkit-media-controls-enclosure {
  border: none;
  background-color: #212325;
}

video {
  width: 100%; /* Make the video fill the container */
  background-color: transparent; /* Remove the background */
  outline: none; /* Remove the default focus outline */
  border: none; /* Remove any borders */
}

.header {
  display: flex;
  width: 100%;
  min-height: 100px;
  border-bottom: 1px solid #e1e1e1;
}

.scrollRight::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.scrollRight::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollRight::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.fontUpdate .font-size_update {
  font-size: 0.675rem;
  line-height: 1.45rem;
  letter-spacing: 1px;
}

.text-Hding {
  font-size: 48px;
  position: relative;
  text-align: center;
}

.text-subheading {
  font-size: 1rem;
}
.WdthSave {
  position: relative;
  width: 72rem;
  margin: 0 auto;
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.WdthSave .wdth50Percetge {
  width: 46%;
  position: relative;
  display: block;
  min-height: 460px;
  border-radius: 15px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

.HgtFxd {
  width: 280px;
  height: 280px;
}

.HgtFxd img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.FlexWdthMn {
  width: calc(100% - 280px);
  position: relative;
}

.flextData {
  display: flex;
  align-items: end;
}

.btnPlay {
  margin: 0px 0px;
  margin-bottom: 0px;
}

.FlexWdthMn h1 {
  margin-bottom: 24px;
  font-size: 44px;
}

.FlexWdthMn h3 {
  margin-bottom: 8px;
  line-height: 1.2;
}

.FlexWdthMn p {
  font-size: 13px;
  font-family: "AktivGro-Regular";
  font-weight: 300;
  line-height: 1.3;
  margin-top: 3px;
  opacity: 0.6;
}

.PriumNberText .TextCOlor {
  font-size: 13px;
  opacity: 0.4;
  line-height: 1.3;
}

.HdingtextLeft {
  font-size: 13px;
  font-weight: 500;
}

.PriumNberText {
  padding: 15px 0px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.PriumNberText.activeMusic::before {
  background: #000;
  height: 100%;
  content: "";
  position: absolute;
  left: -50px;
  width: 195px;
  z-index: -1;
}

.PriumNberText.activeMusic::after {
  background: #000;
  height: 100%;
  content: "";
  position: absolute;
  right: -32px;
  width: 195px;
  z-index: -1;
}

.PriumNberText.activeMusic {
  background-color: #000000;
  z-index: 1;
  position: relative;
}

.textMarginBtm {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  height: 15px;
  background: #fff;
  border-radius: 50%;
}

@media screen and (max-width: 1280px) {
  .WdthSave {
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    padding: 0px 70px;
  }
}

@media screen and (max-width: 1024px) {
  .hidden.flex.flex-col.text-medium.bg-primary.lg\:inset-y-0.lg\:flex.lg\:w-80.border-gray.h-\[calc\(100vh-64px\)\].scrollRight.overflow-y-auto {
    height: auto;
  }

  .lg\:ml-42.w-full.h-\[calc\(100vh-64px\)\].overflow-y-auto.p-8.scrollRight.bg-pageColor {
    height: auto;
  }

  .MobileViewPding {
    display: none;
  }
}

@media screen and (max-width: 950px) {
  .hidden.flex.flex-col.text-medium.bg-primary.lg\:inset-y-0.lg\:flex.lg\:w-80.border-gray.h-\[calc\(100vh-64px\)\].scrollRight.overflow-y-auto {
    height: auto;
  }
  .WdthSave {
    margin-top: 3rem;
    width: 88%;
    margin-bottom: 70px;
    padding: 0px 10px;
  }

  .WdthSave .wdth50Percetge {
    width: 100%;
  }

  .WdthSave .wdth50Percetge:first-child {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 820px) {
  /* .fontUpdate .font-size_update {
    position: absolute;
    top: 60px;
    right: 15px;
  } */

  .PriumNberText {
    display: none;
  }

  .WdthSave {
    margin-top: 3rem;
    width: 88%;
    margin-bottom: 70px;
    padding: 0px 0px;
  }

  .WdthSave .wdth50Percetge {
    width: 100%;
  }

  .WdthSave .wdth50Percetge:first-child {
    margin-bottom: 10px;
  }

  .text-Hding {
    font-size: 40px;
  }

  .MarginTp {
    margin-top: 0px;
    position: relative;
    display: block;
  }

  .MarginTp .p-20 {
    padding: 2rem;
    position: relative;
  }

  .MarginTp h1 {
    font-size: 2rem;
    line-height: 1.1;
  }

  .MarginTp h1 br {
    display: none;
  }
  .MarginTp p br {
    display: none;
  }

  .flex.justify-left.items-center.unMuteIconDta {
    width: 40% !important;
  }

  .MarginTp p {
    font-size: 15px;
    line-height: 1.4;
    font-weight: 400;
  }

  .MarginTp .p-12 {
    padding: 2rem;
    overflow: hidden;
  }

  .MarginTp .h-3 {
    display: none;
  }

  .FlexWdthMn {
    width: 100%;
  }

  .HgtFxd {
    width: 210px;
    height: auto;
    margin: auto;
    margin-top: 20px;
  }

  .scrollRight.p-8 {
    padding: 1rem;
    overflow: hidden;
    height: auto;
  }

  .FlexWdthMn h1 {
    margin-bottom: 15px;
    font-size: 2rem;
    line-height: 1.1;
  }

  .MobileViewPding {
    align-items: flex-start;
    padding: 12px 0px;
    position: relative;
    z-index: 1;
    display: flex;
  }

  .MobileViewPding .w-32 {
    width: 5rem;
  }

  .MobileViewPding .h-32 {
    height: 5rem;
    margin-bottom: 0px;
  }

  .FlexWdthMn h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .FlexWdthMn p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .MobileViewPding .text-gray-500.mb-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 0px;
  }

  .WdthPsrData {
    width: 80%;
    position: relative;
    overflow: hidden;
  }

  .WdthPsrData .text-xl.font-semibold.text-primary.mb-2 {
    font-size: 18px;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .WdthPsrData .text-sm.text-gray-400.block {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .flextData {
    margin-bottom: 40px;
  }

  .MarginTp h1 span.sm\:hidden.text-\[21px\] {
    font-size: 1.7rem;
    line-height: 1;
  }

  .MobileViewPding.activeMusic {
    background-color: #000000;
    border-radius: 0px;
  }

  .MobileViewPding.activeMusic::after {
    background-color: #000000;
    left: -40px;
    content: "";
    position: absolute;
    width: 40px;
    top: 0px;
    height: 100%;
  }

  .MobileViewPding.activeMusic::before {
    background-color: #000000;
    right: -14px;
    content: "";
    position: absolute;
    width: 18px;
    top: 0px;
    height: 100%;
  }

  .mrginBtn {
    margin-bottom: 15px;
  }

  .ShowClass {
    display: block;
    margin-top: 20px;
  }
  .ShowClass .p-8 {
    padding: 1rem;
    border-bottom: 1px solid #d5dadd;
  }

  .PriumNberText {
    display: none;
  }

  .textMarginBtm {
    display: none;
    justify-content: space-between;
  }
}

.border-l-4.border-accent {
  background-color: #eee;
}

.HgtWdthMN {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoDtaAvalible {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.SizeChne {
  width: 25px;
  position: relative;
  margin-right: 10px;
}
.SizeChne img {
  width: 100%;
}

.MrginBtn {
  margin: 25px 0px;
  margin-bottom: 20px;
}

.LineTxt {
  width: 2px;
  height: 24px;
  background-color: #fff;
  position: absolute;
  border-radius: 35px;
  right: 10px;
  transform: rotate(135deg);
  top: -1px;
}

.flex.justify-left.items-center.unMuteIconDta {
  width: 17%;
  margin-left: 20px;
}

.WhiteColor {
  background-color: #fff;
}
.grayColor {
  background-color: #eee;
}
